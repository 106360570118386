<template>
  <section>
    <TitleBar tabsParent="referentiel">
      R&eacute;f&eacute;rentiel
      <template #toolbar-content>
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(creeLe)="{ item }">
          <DateTimeComponent :value="item.creeLe"></DateTimeComponent>
        </template>
        <template #cell(modifieLe)="{ item }">
          <DateTimeComponent :value="item.modifieLe"></DateTimeComponent>
        </template>
        <template #cell(active)="{ item }">
          <b-icon-check-circle-fill v-if="item.active" variant="success">
          </b-icon-check-circle-fill>
          <b-icon-x-circle-fill
            v-if="!item.active"
            variant="danger"
          ></b-icon-x-circle-fill>
        </template>
        <template #cell(blacklist)="{ item }">
          {{
            item.blacklistSocietes
              .map((s) => s)
              .sort()
              .join(", ")
          }}
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import DateTimeComponent from "../../components/DateTimeComponent.vue";
import ReferentielService from "../../services/referentiel.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ReferentielVarieteView",
  components: {
    TitleBar,
    TableButtonGroup,
    ErrorComponent,
    PaginatedTable,
    DateTimeComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.VARIETES),
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let payload = this.getSearchPayload();
        let response = await ReferentielService.getVarietes(payload);
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>